import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Box } from '@rebass/emotion';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>

    <h1 {...{
      "id": "getting-started"
    }}>{`Getting Started`}</h1>
    <p>{`If you have an existing project you want to integrate MDX with, check out
the installation guides.`}</p>
    <Box p={3} bg='lightgray' style={{
      textAlign: 'center',
      fontWeight: 'bold'
    }}>
      <p><a parentName="p" {...{
          "href": "/getting-started/next"
        }}>{`Next.js`}</a>{` |
`}<a parentName="p" {...{
          "href": "/getting-started/gatsby"
        }}>{`Gatsby`}</a>{` |
`}<a parentName="p" {...{
          "href": "/getting-started/create-react-app"
        }}>{`Create React App`}</a>{` |
`}<a parentName="p" {...{
          "href": "/getting-started/react-static"
        }}>{`React Static`}</a>{` |
`}<a parentName="p" {...{
          "href": "/getting-started/webpack"
        }}>{`Webpack`}</a>{` |
`}<a parentName="p" {...{
          "href": "/getting-started/parcel"
        }}>{`Parcel`}</a></p>
    </Box>
    <h2 {...{
      "id": "table-of-contents"
    }}>{`Table of contents`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hello-world"
        }}>{`Hello World`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#syntax"
        }}>{`Syntax`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#markdown"
            }}>{`Markdown`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#jsx"
            }}>{`JSX`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mdx"
            }}>{`MDX`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#working-with-components"
        }}>{`Working with components`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#mdxprovider"
            }}>{`MDXProvider`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-the-wrapper"
            }}>{`Using the wrapper`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#default-exports"
            }}>{`Default exports`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#table-of-components"
            }}>{`Table of components`}</a></li>
        </ul></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#installation-guides"
        }}>{`Installation guides`}</a><ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#scaffold-out-an-app"
            }}>{`Scaffold out an app`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#do-it-yourself"
            }}>{`Do it yourself`}</a></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "hello-world"
    }}>{`Hello World`}</h2>
    <p>{`The smallest MDX example looks like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`# Hello, world!
`}</code></pre>
    <p>{`It displays a heading saying “Hello, world!” on the page.
You could also write it like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<h1>Hello, world!</h1>
`}</code></pre>
    <p>{`This displays the same heading.`}</p>
    <h2 {...{
      "id": "syntax"
    }}>{`Syntax`}</h2>
    <p>{`MDX syntax can be boiled down to being JSX in Markdown.
It’s a superset of Markdown syntax that also supports importing, exporting, and
JSX.`}</p>
    <h3 {...{
      "id": "markdown"
    }}>{`Markdown`}</h3>
    <p>{`Traditionally, Markdown is used to generate HTML.
Many developers like writing markup in Markdown as it often looks more like
what’s intended and it is typically terser.
Instead of the following HTML:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<blockquote>
<p>A blockquote with <em>some</em> emphasis.</p>
</blockquote>
`}</code></pre>
    <p>{`You can write the equivalent in Markdown (or MDX) like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-markdown"
      }}>{`> A blockquote with *some* emphasis.
`}</code></pre>
    <p>{`Markdown is good for `}<strong parentName="p">{`content`}</strong>{`.
MDX supports standard `}<a parentName="p" {...{
        "href": "https://daringfireball.net/projects/markdown/syntax"
      }}>{`Markdown syntax`}</a>{`.
It’s important to understand Markdown in order to learn MDX.`}</p>
    <h3 {...{
      "id": "jsx"
    }}>{`JSX`}</h3>
    <p>{`Recently, more and more developers have started using `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX`}</a>{` to generate HTML
markup.
JSX is typically combined with a frontend framework like React or Vue.
These frameworks add support for components, which let you change repeating
things like the following markup:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h2>Hello, Venus!</h2>
<h2>Hello, Mars!</h2>
`}</code></pre>
    <p>{`…to JSX (or MDX) like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Welcome name="Venus" />
<Welcome name="Mars" />
`}</code></pre>
    <p>{`JSX is good for `}<strong parentName="p">{`components`}</strong>{`.
It makes repeating things more clear and allows for separation of concerns.
MDX fully supports `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/introducing-jsx.html"
      }}>{`JSX syntax`}</a>{`.
Any line that start with the `}<inlineCode parentName="p">{`<`}</inlineCode>{` characters starts a JSX block.`}</p>
    <h3 {...{
      "id": "mdx"
    }}>{`MDX`}</h3>
    <p>{`We love HTML, but we’ve created MDX to let you combine the benefits of Markdown
with the benefits of JSX.
The following example shows how they can be combined.
It’s interactive so go ahead and change the code!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-.mdx"
      }}>{`# Below is a JSX block

<div style={{ padding: '10px 30px', backgroundColor: 'tomato' }}>
  <h2>Try making this heading have the color green</h2>
</div>
`}</code></pre>
    <p>{`MDX supports two more features: `}<a parentName="p" {...{
        "href": "#imports"
      }}>{`imports`}</a>{` and `}<a parentName="p" {...{
        "href": "#exports"
      }}>{`exports`}</a>{`.`}</p>
    <h4 {...{
      "id": "imports"
    }}>{`Imports`}</h4>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/web/javascript/reference/statements/import"
      }}><inlineCode parentName="a">{`import`}</inlineCode>{` (ES2015)`}</a>{` can be used to import components, data, and
documents.`}</p>
    <h5 {...{
      "id": "components"
    }}>{`Components`}</h5>
    <p>{`You can import components, such as your own or from `}<a parentName="p" {...{
        "href": "https://rebassjs.com"
      }}>{`rebass`}</a>{`, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Box, Heading, Text } from 'rebass'

# Here is a JSX block

It is using imported components!

<Box>
  <Heading>Here's a JSX block</Heading>
  <Text>It's pretty neat</Text>
</Box>
`}</code></pre>
    <h5 {...{
      "id": "data"
    }}>{`Data`}</h5>
    <p>{`You can also import data that you want to display:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import snowfallData from './snowfall.json'
import BarChart from './charts/BarChart'

# Recent snowfall trends

2019 has been a particularly snowy year when compared to the last decade.

<BarChart data={snowfallData} />
`}</code></pre>
    <h5 {...{
      "id": "documents"
    }}>{`Documents`}</h5>
    <p>{`You can embed MDX documents in other documents.
This is also known as `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Transclusion"
      }}>{`transclusion`}</a>{`.
You can achieve this by importing an `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` (or `}<inlineCode parentName="p">{`.md`}</inlineCode>{`) file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import License from './license.md'
import Contributing from './docs/contributing.mdx'

# Hello, world!

<License />

---

<Contributing />
`}</code></pre>
    <h4 {...{
      "id": "exports"
    }}>{`Exports`}</h4>
    <p><a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/web/javascript/reference/statements/export"
      }}><inlineCode parentName="a">{`export`}</inlineCode>{` (ES2015)`}</a>{` can be used to export data and components.
For example, you can export metadata like which layout to use or the authors of
a document.
It’s a mechanism for an imported MDX file to communicate with the thing that imports it.`}</p>
    <p>{`Say we import our MDX file, using webpack and React, like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// index.js
import React from 'react'
import MDXDocument, { metadata } from 'posts/post.mdx'

export default () => (
  <>
    <MDXDocument />
    <footer>
      <p>By: {metadata.authors.map(author => author.name).join(', ') + '.'}</p>
    </footer>
  </>
)
`}</code></pre>
    <p>{`And our MDX file looks as follows (note the `}<inlineCode parentName="p">{`export`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// posts/post.mdx
import { sue, fred } from '../data/authors'

export const metadata = {
  authors: [sue, fred]
}

# Post about MDX

MDX is a JSX in Markdown loader, parser, and renderer for ambitious projects.
`}</code></pre>
    <p>{`After bundling and evaluating, we could get something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<h1>Post about MDX</h1>
<p>MDX is a JSX in Markdown loader, parser, and renderer for ambitious projects.</p>
<footer><p>By: Sue, Fred.</p></footer>
`}</code></pre>
    <p>{`This is similar to what frontmatter allows in Markdown, but instead of
supporting only data in something like YAML, MDX lets you use richer JavaScript
structures.`}</p>
    <h2 {...{
      "id": "working-with-components"
    }}>{`Working with components`}</h2>
    <p>{`In addition to rendering components inline, you can also pass in components
to be used instead of the default HTML elements that Markdown compiles to.
This allows you to use your existing components and even CSS-in-JS like
`}<inlineCode parentName="p">{`styled-components`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`components`}</inlineCode>{` object is a mapping between the HTML name and the desired
component you’d like to render.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/App.js
import React from 'react'
import Hello from '../hello.mdx'

const MyH1 = props => <h1 style={{ color: 'tomato' }} {...props} />
const MyParagraph = props => <p style={{ fontSize: '18px', lineHeight: 1.6 }} />

const components = {
  h1: MyH1,
  p: MyParagraph
}

export default () => <Hello components={components} />
`}</code></pre>
    <p>{`You can also import your components from another location like your UI library:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react'
import Hello from '../hello.mdx'

import {
  Text,
  Heading,
  Code,
  InlineCode
} from '../my-ui-library'

export default () =>
  <Hello
    components={{
      h1: Heading,
      p: Text,
      code: Code,
      inlineCode: InlineCode
    }}
  />
`}</code></pre>
    <p>{`With the above, the `}<inlineCode parentName="p">{`Heading`}</inlineCode>{` component will be rendered for any `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`Text`}</inlineCode>{`
for `}<inlineCode parentName="p">{`p`}</inlineCode>{` elements, and so on.`}</p>
    <p>{`In addition to HTML elements, there is one special mapping: `}<inlineCode parentName="p">{`inlineCode`}</inlineCode>{` can be
used for code inside paragraphs, tables, etc.`}</p>
    <p>{`See the `}<a parentName="p" {...{
        "href": "#table-of-components"
      }}>{`Table of components`}</a>{` for supported names.`}</p>
    <h3 {...{
      "id": "mdxprovider"
    }}>{`MDXProvider`}</h3>
    <p>{`If you’re using an app layout that wraps your application, you can use
the `}<inlineCode parentName="p">{`MDXProvider`}</inlineCode>{` to only pass your components in one place:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/App.js
import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import { Heading, Text, Pre, Code, Table } from './components'

const components = {
  h1: Heading.H1,
  h2: Heading.H2,
  // …
  p: Text,
  code: Pre,
  inlineCode: Code
}

export default props =>
  <MDXProvider components={components}>
    <main {...props} />
  </MDXProvider>
`}</code></pre>
    <p>{`This allows you to remove duplicated component passing and importing.
It will typically go in layout files.`}</p>
    <h4 {...{
      "id": "using-the-wrapper"
    }}>{`Using the wrapper`}</h4>
    <p>{`The MDXProvider has a special `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{` key that you can use in the component
mapping.
With your wrapper component you can set the layout of your document, inject
styling, or even manipulate the children passed to the component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// src/App.js
import React from 'react'
import { MDXProvider } from '@mdx-js/react'

const Wrapper = props => <main style={{ padding: '20px', backgroundColor: 'tomato' }} {...props} />

export default ({ children }) =>
  <MDXProvider components={{ wrapper: Wrapper }}>
    {children}
  </MDXProvider>
`}</code></pre>
    <p>{`If you would like to see more advanced usage, see the
`}<a parentName="p" {...{
        "href": "/guides/wrapper-customization"
      }}>{`wrapper customization guide`}</a>{`.`}</p>
    <h4 {...{
      "id": "default-exports"
    }}>{`Default exports`}</h4>
    <p>{`Sometimes from an MDX file you might want to override the wrapper.
This is especially useful when you want to override layout for a single entry
point at the page level.
To achieve this you can use the ES default `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/web/javascript/reference/statements/export"
      }}>{`export`}</a>{` and it will wrap your MDX
document `}<em parentName="p">{`instead`}</em>{` of the wrapper passed to MDXProvider.`}</p>
    <p>{`You can declare a default export as a function:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Layout from './Layout'

export default ({ children }) => <Layout some='metadata' >{children}</Layout>

# Hello, world!
`}</code></pre>
    <p>{`Or directly as a component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import Layout from './Layout'

export default Layout

# Hello, world!
`}</code></pre>
    <p>{`Either works.
Whatever you prefer!`}</p>
    <h3 {...{
      "id": "table-of-components"
    }}>{`Table of components`}</h3>
    <p><inlineCode parentName="p">{`MDXProvider`}</inlineCode>{` uses `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context`}</a>{` to provide the component mapping
internally to MDX when it renders.
The following components are rendered from Markdown, so these can be keys in the
component object you pass to `}<inlineCode parentName="p">{`MDXProvider`}</inlineCode>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tag`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Syntax`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`p`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#paragraph"
            }}>{`Paragraph`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`h1`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#heading"
            }}>{`Heading 1`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`h2`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#heading"
            }}>{`Heading 2`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`##`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`h3`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#heading"
            }}>{`Heading 3`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`###`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`h4`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#heading"
            }}>{`Heading 4`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`####`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`h5`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#heading"
            }}>{`Heading 5`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`#####`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`h6`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#heading"
            }}>{`Heading 6`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`######`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`thematicBreak`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#thematicbreak"
            }}>{`Thematic break`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`***`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`blockquote`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#blockquote"
            }}>{`Blockquote`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`>`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ul`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#list"
            }}>{`List`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`-`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ol`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#list"
            }}>{`Ordered list`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1.`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`li`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#listitem"
            }}>{`List item`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`table`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#table"
            }}>{`Table`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`--- | --- | ---`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`tr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#tablerow"
            }}>{`Table row`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`This | is | a | table row`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`td`}</inlineCode>{`/`}<inlineCode parentName="td">{`th`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#tablecell"
            }}>{`Table cell`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`pre`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#code"
            }}>{`Pre`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#code"
            }}>{`Code`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`em`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#emphasis"
            }}>{`Emphasis`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`_emphasis_`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`strong`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#strong"
            }}>{`Strong`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`**strong**`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`delete`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#delete"
            }}>{`Delete`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`~~strikethrough~~`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`code`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#inlinecode"
            }}>{`InlineCode`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`hr`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#break"
            }}>{`Break`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`---`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`a`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#link"
            }}>{`Link`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`<https://mdxjs.com>`}</inlineCode>{` or `}<inlineCode parentName="td">{`[MDX](https://mdxjs.com)`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`img`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://github.com/syntax-tree/mdast#image"
            }}>{`Image`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`![alt](https://mdx-logo.now.sh)`}</inlineCode></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "installation-guides"
    }}>{`Installation guides`}</h2>
    <p>{`Now that we’ve gone over how MDX works, you’re ready to get installing.`}</p>
    <h3 {...{
      "id": "scaffold-out-an-app"
    }}>{`Scaffold out an app`}</h3>
    <p>{`If you’re the type of person that wants to scaffold out an app quickly and start
playing around you can use `}<inlineCode parentName="p">{`npm init`}</inlineCode>{`:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/webpack"
        }}><inlineCode parentName="a">{`webpack`}</inlineCode></a></li>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/parcel"
        }}><inlineCode parentName="a">{`parcel`}</inlineCode></a></li>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/next"
        }}><inlineCode parentName="a">{`next`}</inlineCode></a></li>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/create-react-app"
        }}><inlineCode parentName="a">{`create-react-app`}</inlineCode></a></li>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/gatsby"
        }}><inlineCode parentName="a">{`gatsby`}</inlineCode></a></li>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/x0"
        }}><inlineCode parentName="a">{`x0`}</inlineCode></a></li>
      <li parentName="ul"><inlineCode parentName="li">{`npm init mdx`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "/getting-started/react-static"
        }}><inlineCode parentName="a">{`react-static`}</inlineCode></a></li>
    </ul>
    <h3 {...{
      "id": "do-it-yourself"
    }}>{`Do it yourself`}</h3>
    <p>{`If your favorite bundler or framework isn’t listed above, or if you have a
custom use case, you can of course do it yourself.
The below rendering function is what we use for our MDX integration tests:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const babel = require('@babel/core')
const React = require('react')
const {renderToStaticMarkup} = require('react-dom/server')

const transform = code =>
  babel.transform(code, {
    plugins: [
      '@babel/plugin-transform-react-jsx',
      '@babel/plugin-proposal-object-rest-spread'
    ]
  }).code

const renderWithReact = async mdxCode => {
  const jsx = await mdx(mdxCode, {skipExport: true})
  const code = transform(jsx)
  const scope = {mdx: createElement}

  const fn = new Function(
    'React',
    ...Object.keys(scope),
    \`\${code}; return React.createElement(MDXContent)\`
  )

  const element = fn(React, ...Object.values(scope))
  const components = {
    h1: ({children}) =>
      React.createElement('h1', {style: {color: 'tomato'}}, children)
  }

  const elementWithProvider = React.createElement(
    MDXProvider,
    {components},
    element
  )

  return renderToStaticMarkup(elementWithProvider)
}
`}</code></pre>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      